import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../Alertmsg';

const AuthenticatedOnboarding = ({ children }) => {
const users = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  
 useEffect(() => {
  if (users?.onboarding_count == 1) {
    if(users?.customer_type == "seller"){
       // navigate('/Seller/profile')
    }
    else{
      errorMessage("Your Account is Allready Onboarding !!")
        navigate('/buyer/profile') 
    }
   
 }
}, [users]);

  return <>
  {children}</>;
};
export default AuthenticatedOnboarding;