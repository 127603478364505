import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Authenticatedassociative = ({ children }) => {
    const token = localStorage.getItem('accessToken')
  const navigate = useNavigate();
  
 useEffect(() => {
  if (!token) {
    navigate('/associative_users/login')
 }
}, [token]);

  return <>
  {children}</>;
};
export default Authenticatedassociative;