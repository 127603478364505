import axios from 'axios';
import { Subscription, removeAuth } from '../helpers/auth';
import { defaultConfig } from '../config';
import { errorMessage } from '../Alertmsg';


const axiosInt = axios.create({ 
    baseURL: defaultConfig.baseAPIUrl
}
);

axiosInt.interceptors.response.use(
  (response) => {
    if(response.data != undefined && response.data.message === "Unauthenticated."){ 
      removeAuth();  
      window.location.href = '/';
    }
    if((response.data != undefined && response.data.message === "SubscriptionsFailed")){
      errorMessage("Please Do Subscriptions");
      const timer = setTimeout(() => {
        Subscription();
      }, 2000);
      return () => clearTimeout(timer);
     
    }
    return response;
  },
  (error) =>{
    console.log(error)
    if(error.response && error.response.status === 401){
      removeAuth();  
      window.location.href = '/';
    }
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
  }
    
);

export default axiosInt;
