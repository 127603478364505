export const defaultConfig = {
    // baseAPIUrl:"http://localhost/maven-backend/api/", 
    // baseAPIUrl:"https://app.themaven.co/backend/api/",
    baseAPIUrl:"https://themaven.superfastech.com/maven-backend/api/", 
    };
    
export const pusherConfig = {
  key:"dc6fc423eae4edbfc5ae",
  cluster:"ap2"
};

export const UserType = {
  admin:"Admin",
  user:"User",
  support_user:"SupportUser",
  
};
































































    