import React, { Suspense, Fragment, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginRegisterLayoutSeller from "./Layout/LoginRegisterLayoutSeller";
import LoginRegisterLayoutBuyer from "./Layout/LoginRegisterLayoutBuyer";
import BaseLayout from "./Layout/BaseLayout";
import LoginLayout from "./Layout/LoginLayout";
import SellerDashboardLayout from "./Layout/SellerDashboardLayout";
import BuyerDashboardLayout from "./Layout/BuyerDashboardLayout";
import Authenticated from "./Guard/Authenticated";
import Guest from "./Guard/Guest";
import AuthenticatedOnboarding from "./Guard/AuthenticatedOnboarding";
import GuestOnboarding from "./Guard/GuestOnboarding";
import BuyerRouteAuth from "./Guard/BuyerRouteAuth";
import SellerRouteAuth from "./Guard/SellerRouteAuth";
import AssociativeLoginLayout from "./Associative_Users/Layout/AssociativeLoginLayout";
import AssociativeDashboardLayout from "./Associative_Users/Layout/AssociativeDashboardLayout";
import Guestassociative from "./Associative_Users/Guard/Guestassociative";
import Authenticatedassociative from "./Associative_Users/Guard/Authenticatedassociative";

const users = JSON.parse(localStorage.getItem("userData"));
export function Routers() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>....</div>}>
          <Routes>
            {routes.map((route, i) => {
              const Guard = route?.guard || Fragment;
              const Onbord = route?.onbord || Fragment;
              const Auth = route?.auth || Fragment;
              const Layout = route?.layout || Fragment;
              const Component = route?.element;
              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <Guard>
                      <Onbord>
                        <Auth>
                          <Layout>
                            <Component />
                          </Layout>
                        </Auth>
                      </Onbord>
                    </Guard>
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}
const routes = [
  {
    guard: Guest,
    layout: LoginLayout,
    exact: true,
    path: "/",
    element: lazy(() => import("./Auth/Login")),
  },

  {
    guard: Guest,
    layout: BaseLayout,
    exact: true,
    path: "/forget-password",
    element: lazy(() => import("./Auth/Forget_password")),
  },
  {
    guard: Guest,
    layout: LoginRegisterLayoutBuyer,
    exact: true,
    path: "/buyer/register",
    element: lazy(() => import("./Buyer/BuyerRegister")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    layout: BuyerDashboardLayout,
    auth: SellerRouteAuth,
    exact: true,
    path: "/buyer/profile",
    element: lazy(() => import("./Buyer/BuyerProfile")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/dashboard",
    element: lazy(() => import("./Buyer/Dashboard")),
  },
  {
    guard: Authenticated,
    onbord: AuthenticatedOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    path: "/buyer/onboarding",
    element: lazy(() => import("./Buyer/Onboarding")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/campaigns",
    element: lazy(() => import("./Buyer/Campaigns/AllCampaigns")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/docusign/completed",
    element: lazy(() => import("./Buyer/Campaigns/PendaDocSuccess")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/campaigns/:id",
    element: lazy(() => import("./Buyer/Campaigns/AllCampaigns")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/campaigns/view/:id",
    element: lazy(() => import("./Buyer/Campaigns/CampaignsDetails")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/messages",
    element: lazy(() => import("./Chat/index")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/data-room",
    element: lazy(() => import("./DataRoom/Index")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/data-room/:id",
    element: lazy(() => import("./DataRoom/DataRoomView")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/messages/:id",
    element: lazy(() => import("./Chat/index")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/subscription-list",
    element: lazy(() => import("./Stripe/Index")),
  },

   {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: SellerRouteAuth,
    exact: true,
    layout: BuyerDashboardLayout,
    path: "/buyer/notification",
    element: lazy(() => import("./Notification/Index")),
  },
  {
    guard: Guest,
    layout: LoginRegisterLayoutSeller,
    exact: true,
    path: "/seller/register",
    element: lazy(() => import("./Seller/SellerRegister")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: BuyerRouteAuth,
    exact: true,
    layout: SellerDashboardLayout,
    path: "/seller/profile",
    element: lazy(() => import("./Seller/SellerProfile")),
  },

  {
    guard: Authenticated,
    onbord: AuthenticatedOnboarding,
    auth: BuyerRouteAuth,
    exact: true,
    path: "/seller/onboarding",
    element: lazy(() => import("./Seller/Onboarding")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    auth: BuyerRouteAuth,
    exact: true,
    layout: SellerDashboardLayout,
    path: "/seller/dashboard",
    element: lazy(() => import("./Seller/Dashboard")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/campaigns",
    element: lazy(() => import("./Seller/Campaigns/AllCampaigns")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/campaigns/create",
    element: lazy(() => import("./Seller/Campaigns/Create")),
  },
  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/campaigns/edit/:id",
    element: lazy(() => import("./Seller/Campaigns/Edit")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/campaigns/view/:id",
    element: lazy(() => import("./Seller/Campaigns/View")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/messages",
    element: lazy(() => import("./Chat/index")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/messages/:id",
    element: lazy(() => import("./Chat/index")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/data-room",
    element: lazy(() => import("./DataRoom/Index")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/data-room/:id",
    element: lazy(() => import("./DataRoom/DataRoomView")),
  },

  {
    guard: Authenticated,
    onbord: GuestOnboarding,
    exact: true,
    layout: SellerDashboardLayout,
    auth: BuyerRouteAuth,
    path: "/seller/notification",
    element: lazy(() => import("./Notification/Index")),
  },

  {
    guard: Authenticated,
    layout:
      users?.customer_type === "investor"
        ? BuyerDashboardLayout
        : SellerDashboardLayout,
    exact: true,
    path: "/subscription-list",
    element: lazy(() => import("./Stripe/Index")),
  },

  {
    guard: Authenticated,
    layout:
      users?.customer_type === "investor"
        ? BuyerDashboardLayout
        : SellerDashboardLayout,
    exact: true,
    path: "/subscription/success",
    element: lazy(() => import("./Stripe/Subscriptionsuccess")),
  },

  //Associative_Users

  {
    guard: Guestassociative,
    layout: AssociativeLoginLayout,
    exact: true,
    path: "/associative_users/login",
    element: lazy(() =>
      import("./Associative_Users/AssociativeAuth/AssociativeLogin")
    ),
  },

  {
    guard: Guestassociative,
    exact: true,
    path: "/associative_users/register/:id",
    element: lazy(() =>
      import("./Associative_Users/AssociativeAuth/AssociativeRagister")
    ),
  },
  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/dashboard",
    element: lazy(() => import("./Associative_Users/AssociativeDashboard")),
  },

  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/profile",
    element: lazy(() => import("./Associative_Users/AssociativeProfile")),
  },

  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/campaigns",
    element: lazy(() =>
      import("./Associative_Users/AssociativeCampaigns/AssociativeAllCampaigns")
    ),
  },

  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/campaigns/:id",
    element: lazy(() =>
      import(
        "./Associative_Users/AssociativeCampaigns/AssociativeCampaignsview"
      )
    ),
  },

  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/messages",
    element: lazy(() => import("./Associative_Users/AssociativeChat/index")),
  },
  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/messages/:id",
    element: lazy(() => import("./Associative_Users/AssociativeChat/index")),
  },
  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/data-room",
    element: lazy(() =>
      import("./Associative_Users/AssociativeDealData/Index")
    ),
  },

  {
    guard: Authenticatedassociative,
    exact: true,
    layout: AssociativeDashboardLayout,
    path: "/associative_users/data-room/:id",
    element: lazy(() =>
      import("./Associative_Users/AssociativeDealData/DataRoomView")
    ),
  },
];
