export const apiUrls = {
      //maven api
                       login:'login',
                       register:'register',
                       profile:'profile',
                       dashboard:'dashboard',
                       updateProfile:'profile',
                       createusernda:'create-user-nda',
                       investorsigndocument:'investor/sign-document',
                       addwatchlist:'add-watch_list',
                       existEmail:'exist-email',
                       logout:'logout',
                       onboarding:'onboarding',
                       forgetPassword:'forget/password',
                       updateprofileimage:'upload-profile',
                       sellercampaignlist:'seller/campaign-list',
                       investorcampaignlist:'investor/campaign-list',
                       campaignview:'investor/campaign-view',
                       chatUserList:'chat/all-users',
                       saveChatMsg:'chat/messages',
                       getMessage:'chat/getMessage',
                       createGroup:'chat/create-group',
                       groupList:'chat/list-group',
                       groupsavechat:'chat/store-messages',
                       groupchatmsg:'chat/get-messages',
                       subscriptionlist:'subscription/list',
                       subscriptionpaymentcall:'subscription/payment-call',
                       subscriptionadd:'subscription/add',
                       campaignstore:'seller/campaign-store',
                       commonimageadd:'common-image-add',
                       campaigndetail:'seller/campaign-detail',
                       campaignedit:'seller/campaign-edit',
                       campaigndelete:'seller/campaign-delete',
                       campaignlive:'seller/campaign-live',
                       contacttomaven:'contact-to-maven',
                       groupdetail:'chat/group/detail',
                       dealdataroom:'deal-data-room',
                       dealdataroomdetiels:'deal-data-room',
                       sellerdealdataroomstore:'seller/deal-data-room-store',
                       addMember:'chat/addMember',
                       dealdataroomdelete:'seller/deal-data-room-delete',
                       dealdataroomfileremove:'deal-data-room/file-remove',
                       messagecountnotification:'message-count-notification',
                       exportchat:'export-chat',
                       allnotification:'all-notification',
                       dealdataroomfileassign:'deal-data-room/file-assign',
                       ///assistive-user
                       assistiveuserregister:'assistive-user-register',
                       assistiveuserlogin:'assistive-user-login',
                       assistiveuploadimage:'assistive-upload-profile',
                       assistiveuserdetail:'assistive-user-detail',
                       assistiveuserupdate:'assistive-user-update',
                       assistivecampaigns:'assistive/campaigns',
                       assistivedealdataroom:'assistive/deal-data-room',
                       assistivecampaigndetail:'assistive/campaign-detail'

                 };
                 