import React from 'react'
import {Link} from 'react-router-dom';
export default function AssociativeFooter() {
  return (
    <React.Fragment>
      <footer className="footer">
            <section className="footer-outline py-lg-5 py-md-5 py-3">
                <div className="container-fluid top-container width1300">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-5 mb-4">
                            <div className="luxurycarmarket">
                                <figure className="mb-4">
                                    <Link to="/associative_users/dashboard"><img src="/img/Logowhite-logo.png" className="img-fluid" alt="" /></Link>
                                </figure>
                                <p className="site">www.maven.co</p>
                                <p className="text_white">Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-md-5 mb-5 ps-lg-5 ps-md-5 ps-3">
                            <div className="footer_links">
                                <h4>Site Links</h4>
                                <div className="d-flex">
                                    <ul>
                                        <li><Link to="#" className="text_white">Privacy Policy</Link></li>
                                        <li><Link to="#" className="text_white">Disclamiers</Link></li>
                                        <li><Link to="#" className="text_white">Terms & Conditions</Link></li>
                                        <li><Link to="#" className="text_white">Cookies used</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="footer_links get_in_touch">
                                <h4>Stay Tuned With Us</h4>
                                <ul className="arrow-list mt-0">
                                    <li className="mt-2 mb-2">
                                        <Link to="#">
                                            <i className="fa-sharp fa-solid fa-location-dot"></i>
                                            <label>CT Hub, 2 Kallang Ave#09-27 Singapore 339407</label>
                                        </Link>
                                    </li>
                                    <li className="mt-2 mb-2">
                                        <Link to="#">
                                            <i className="fa-solid fa-envelope-open"></i>
                                            <label>mavan@hotmail.com</label>
                                        </Link>
                                    </li>
                                    <li className="mt-2 mb-2">
                                        <Link to="#">
                                            <i className="fa-sharp fa-solid fa-phone-volume"></i>
                                            <label>+62 123 456 789</label>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="rights">
                <div className="container-fluid top-container width1300" style={{borderTop: "1px solid grey" }}>
                    <div className="align-items-center">
                        <p>© {new Date().getFullYear()} Maven, All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    </React.Fragment>
  )
}
