import React from "react";
import SellerHeader from "../Component/SellerHeader";
import SellerFooter from "../Component/SellerFooter";

export default function SellerDashboardLayout(props) {
  return (
    <>
      <SellerHeader />
      <div className="minHeight">{props.children}</div>
      <SellerFooter />
    </>
  );
}
