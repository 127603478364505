import React from 'react'
import AssociativeFooter from '../Component/AssociativeFooter'
import AssociativeHeader from '../Component/AssociativeHeader'

export default function AssociativeDashboardLayout(props) {
  return (
    <> 
   <AssociativeHeader/>
    {props.children}
  <AssociativeFooter/>
    </>
  )
}
