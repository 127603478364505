import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../Alertmsg';
const BuyerRouteAuth = ({ children }) => {
const users = JSON.parse(localStorage.getItem("userData"));
console.log(users)
  const navigate = useNavigate();
 useEffect(() => {
    if(users?.customer_type != "seller"){
        navigate('/buyer/dashboard')
        errorMessage("Your have No Access !!")
    }
   
}, [users]);

  return <>
  {children}</>;
};
export default BuyerRouteAuth;