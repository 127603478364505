import React from "react";

export default function AssociativeLoginLayout(props) {
  return (
    <React.Fragment>
      <div class="container">
        <div class="row  mt-5">
          <div class="col-md-4">{props.children}</div>
          <div class="col-md-2"></div>
          <div class="col-md-6">
            <img
              src="/img/login_img.png"
              alt="no image"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
