import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../Alertmsg';
const SellerRouteAuth = ({ children }) => {
const users = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
 useEffect(() => {
    if(users?.customer_type != "investor"){
        navigate('/Seller/dashboard')
        errorMessage("Your have No Access !!")
    }
   
}, [users]);

  return <>
  {children}</>;
};
export default SellerRouteAuth;