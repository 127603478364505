import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Guestassociative = ({ children }) => {
    const token = localStorage.getItem('accessToken')
  const navigate = useNavigate();
 useEffect(() => {
  if (token) {
      navigate("/associative_users/dashboard");
 }
}, [token]);

  return <>
  {children}</>;
};
export default Guestassociative;