import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { removeAuth } from "../../helpers/auth";
import { useSelector } from "react-redux";
import { callAPI } from "../../utils/apiUtils";
import { apiUrls } from "../../utils/apiUrls";
import { errorMessage, successMessage } from "../../Alertmsg";
import {
  BellFill,
  ChatLeftTextFill,
  BoxArrowRight,
} from "react-bootstrap-icons";
export default function AssociativeHeader() {
  const [notification, setNotification] = useState({});
  const location = useLocation();
  let navigate = useNavigate();
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const header = pathname.split("/");

  const logout = async () => {
    try {
      const response = await callAPI(apiUrls.logout, {}, "GET");
      if (response.data.status == true) {
        successMessage(response.data.message);
        navigate("/associative_users/login");
        removeAuth();
      } else {
        errorMessage(response.data.message);
      }
    } catch (err) {
      errorMessage(err.message);
    }
  };

  const handleLogout = () => {
    // logout();
    navigate("/associative_users/login");
    removeAuth();
  };
  const users = JSON.parse(localStorage.getItem("userData"));
  const user = useSelector((state) => state.UserProfile.profile);

  const Notification = async () => {
    try {
      let query = { type: "SupportUser" };
      const response = await callAPI(
        apiUrls.messagecountnotification,
        query,
        "GET"
      );
      if (response.data.status == true) {
        setNotification(response.data.data);
      } else {
        errorMessage(response.data.message);
      }
    } catch (err) {
      errorMessage(err.message);
    }
  };

  useEffect(() => {
    Notification();
  }, []);

  return (
    <React.Fragment>
      <header className={header[1] !== "" ? "  " : "header"}>
        <nav className="navbar navbar-expand-xl navbar-custom">
          <div className="container-fluid top-container">
            <NavLink className="navbar-brand" to="/associative_users/dashboard">
              <img src="/img/logo-icon.png" className="img-fluid" alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img
                src="/img/logo-icon.png"
                className="img-fluid hambuger_menu"
                alt=""
              />
            </button>
            <div
              className="collapse navbar-collapse mobile_nav"
              id="navbarSupportedContent"
            >
              <ul className="d-flex">
                <li className="dropdown" style={{ marginLeft: "10px" }}>
                  <Link
                    className="login_user_pic"
                    to="/associative_users/campaigns"
                  >
                    Campaigns
                  </Link>
                </li>
              </ul>
              <div
                class="collapse navbar-collapse "
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <Link class="nav-link" to="/associative_users/messages">
                      <ChatLeftTextFill />
                      {notification?.message_count > 0 ? (
                        <sup className="superSetIcon">
                          {notification?.message_count}
                        </sup>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </li>
                </ul>

                <ul className="d-flex">
                  <li className="dropdown" style={{ marginLeft: "10px" }}>
                    <Link
                      className="login_user_pic dropdown-toggle bellIcon"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <BellFill />
                      {notification?.notification_count > 0 ? (
                        <sup className="superSetIcon">
                          {notification?.notification_count}
                        </sup>
                      ) : (
                        <></>
                      )}
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end user_top_dropdown move-to-right">
                      {notification?.last_five_notification != undefined &&
                        notification?.last_five_notification?.length > 0 &&
                        notification?.last_five_notification?.map((val, i) => (
                          <li key={i}>
                            <Link className="dropdown-item" to="#">
                              {val?.message?.message}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                </ul>

                <ul className="d-flex">
                  <li className="dropdown" style={{ marginLeft: "10px" }}>
                    <Link
                      className="login_user_pic dropdown-toggle"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          user?.photoURL
                            ? user?.photoURL
                            : users?.photoURL
                            ? users?.photoURL
                            : "/img/chat-pic-2.png"
                        }
                        className="img-fluid"
                        alt=""
                      />
                      <h6 className="d-inline ms-1 mb-0 fs-6">
                        {user.displayName
                          ? user.displayName
                          : users?.displayName}
                      </h6>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end user_top_dropdown move-to-right">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/associative_users/profile"
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/associative_users/dashboard"
                        >
                          My Dashboard
                        </Link>
                      </li>
                      <li onClick={handleLogout}>
                        <Link className="dropdown-item" to="#">
                          <BoxArrowRight size={19} /> &nbsp;&nbsp; Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}
