import {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorMessage } from '../Alertmsg';

const GuestOnboarding = ({ children }) => {
const users = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();
  
 useEffect(() => {
  if (users?.onboarding_count == 0) {
    if(users?.customer_type == "seller"){
       // navigate('/Seller/Onboarding')
    }
    else{
      //errorMessage("Onboarding Your Account!!")
      navigate('/buyer/Onboarding') 
    }
    
   
 }
}, [users]);

  return <>
  {children}</>;
};
export default GuestOnboarding;